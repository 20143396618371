/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage
// import Logo from '../../../public/intro_face.jpeg';

const SideBar = () => (
  <section id='sidebar'>
    <section id='intro'>
      <Link to='/' className='logo'>
        <img src={`${PUBLIC_URL}/images/intro_face.jpg`} alt='' />
        {/* <img src={Logo} alt='' /> */}
      </Link>
      <header>
        <h2>Ofir Yaron</h2>
        <p><a href='mailto:ofir@ofiryaron.com'>ofir@ofiryaron.com</a></p>
      </header>
    </section>

    <section className='blurb'>
      <h2>About</h2>
      <p>
        Hi, my name is Ofir and I am passionate about leveraging technology to drive meaningful change.
        As a Cloud Solution Architect and Software Engineer,
        I have a strong background in developing cutting-edge software and creating secure,
        scalable cloud solutions. I also have extensive expertise in 3D part manufacturing.
        As the former CTO of <a href='https://www.wobi.co.il'>Wobi</a>,
        an innovative InsureTech company, I have a deep understanding of the challenges and opportunities within the insurance industry.
        Now, as a self-employed freelance Cloud Solution Architect and Software Engineer Advisor,
        I bring my expertise to help businesses transform their technology infrastructure.
        Let's connect and explore how I can assist you in creating meaningful impact through advanced solutions.
      </p>
      <ul className='actions'>
        <li>
          {!window.location.pathname.includes('/about') ? <Link to='/about' className='button'>Learn More</Link> : <Link to='/about' className='button'>About Me</Link>}
        </li>
      </ul>
    </section>

    <section id='footer'>
      <ContactIcons />
      <p className='copyright'>
        &copy; Ofir Yaron &nbsp;
        <Link to='/'>ofiryaron.com</Link>
        .
      </p>
    </section>
  </section>
);

export default SideBar;
