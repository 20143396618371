/* eslint-disable */
import React, { Suspense, lazy, useEffect } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles
import ReactGA4 from 'react-ga4';

// 'G-PFEQKFQKJH'
ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Blog = lazy(() => import('./pages/Blog'));
const Contact = lazy(() => import('./pages/Contact'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Printables = lazy(() => import('./pages/Printables'));
const InterestCalculator = lazy(() => import('./pages/InterestCalculator'));
const PostPage = lazy(() => import('./pages/PostPage'));

const App = () => {
  return (
    <HashRouter basename={PUBLIC_URL}>
      <Suspense fallback={<Main />}>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/about" component={About} />
          <Route path="/blog" component={Blog} />
          <Route path="/printables" component={Printables} />
          <Route path="/intcalc" component={InterestCalculator} />
          <Route path="/contact" component={Contact} />
          <Route path="/post/:name" component={PostPage} />
          <Route component={NotFound} status={404} />
        </Switch>
      </Suspense>
    </HashRouter>);
};

export default App;
